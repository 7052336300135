@import "components/_variables.scss";

.btn {
  opacity: 1;
  padding: 15px 20px 15px 20px;
  border-radius: 4px;
  border: 0;
  background-color: $button-background;
  color: $button-text-color;
  font-weight: 600;

  &:focus {
    outline: thin dotted;
  }

  &:hover {
    opacity: 0.8;
    transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
  }
}

.btn-submit {
  @extend .btn;
  background-color: $button-background;
  color: $button-text-color;
  opacity: 1;

  &:disabled {
    background-color: #afafaf;
  }

  &:enabled:hover {
    opacity: 0.8;
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
  }

  &:focus {
    @extend .btn:focus;
  }
}

.btn-primary {
  @extend .btn;

  &:disabled {
    background-color: #afafaf;
  }

  &:focus {
    @extend .btn:focus;
  }
}

.btn-block {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

.btn-standard {
  @extend .btn;
  opacity: 1;
  margin-top: 10px;
  font-family: "Raleway", sans-serif;
  border-radius: 0px;
}
