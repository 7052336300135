.user-landing-page {
  @media only screen and (max-width: 500px) {
    margin-top: 0px;
  }
}

.tech-images {
  @media only screen and (max-width: 500px) {
    margin-top: 50px;
  }
}
