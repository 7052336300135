@import "components/_variables.scss";

.user-card {
  display: inline-block;

  &__image {
    opacity: 0.65;
    object-fit: cover;
    cursor: pointer;
    width: 10vw;
    height: 10vw;
    min-width: 100px;
    min-height: 100px;
    border-radius: 5%;
    border: 2px $portrait-border-color solid;
    box-shadow: 0;
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;

    @media only screen and (max-width: 1400px) {
      width: 11vw;
      height: 11vw;
    }

    @media only screen and (max-width: 900px) {
      width: 12vw;
      height: 12vw;
    }

    @media only screen and (max-width: 600px) {
      width: 10vw;
      height: 10vw;
    }

    &:hover {
      opacity: 0.9;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
    }

    &__noclick {
      @extend .user-card__image;
      cursor: default;

      &:hover {
        box-shadow: none;
      }
    }
  }

  &__display-name {
    padding-top: 10px;
    font-size: 24px;
    font-weight: 200;
    text-decoration: none;
    color: inherit;
  }
}
