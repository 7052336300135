@import "components/_variables.scss";

.App {
  height: 100%;
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;

  @media only screen and (max-width: 900px) {
    width: 5px;         
  }

}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}