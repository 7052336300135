.resume-page {
  height: 100%;
  width: 100%;

  &__content {
    height: 100%;
    width: 100%;
    margin-top: 80px;

    &__logo {
      width: 100px;
      margin-bottom: 10px;
    }

    &__title {
      font-size: 18px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &__organization {
      font-size: 16px;
    }

    &__subtitle {
      font-size: 14px;
    }
  }
}

.vertical-timeline-custom-line::before {
  background: #f7f7f7 !important;
}
