@import "components/_variables.scss";

@import url("https://fonts.googleapis.com/css?family=Poiret+One|Raleway:100,200,400,600,800&display=swap");

html {
  height: 100%;
  width: 100%;
  min-height: 100%;
}

body {
  height: 100%;
  width: 100%;
  min-height: 100%;
  font-family: "Raleway", sans-serif;
  margin: 0;
  background-color: #22272e;
}

#root {
  height: 100%;
  width: 100%;
}

a,
a:hover {
  color: white;
  text-decoration: none;
}

.toastr.rrt-success {
  background-color: white !important;
  color: green !important;
}

.toastr-icon {
  fill: green !important;
}
