@import "components/_variables.scss";

.sidebar {
  opacity: 1;
  color: $accent-color;

  @media only screen and (max-width: 900px) {
    margin: 75px;
  }

  &__full {
    height: 100%;
    width: 200px; /* Should be a global variable */

    &__brand {
      font-size: 18px;
    }
  }

  &__small {
    height: 100%;
    width: 75px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba($color: #161616, $alpha: 0.9);
    overflow: hidden;
    padding-top: 10px;
    text-align: center;

    @media only screen and (max-width: 900px) {
      height: 6.5%;
      width: 100%;
      background-color: rgba($color: #161616, $alpha: 1);
    }

    &__brand {
      font-family: "Raleway", sans-serif;
      font-weight: 200;
      font-size: 1vw;
      color: $light-gray;

      @media only screen and (max-width: 900px) {
        display: none;
      }
    }

    &__brand:hover {
      font-weight: 400;
    }

    a:hover {
      text-decoration: none;
    }

    &__menu {
      height: 100%;

      &__main {
        @media only screen and (min-width: 900px) {
          width: 100%;
          display: block;
          padding: 0;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          margin-top: 20vh;
          margin-bottom: auto;
        }

        &__menu-item {
          height: 60px;
          color: #c6c6c6;
          font-size: 18px;
          cursor: pointer;
          text-align: center;

          @media only screen and (max-width: 900px) {
            display: inline-block;
            width: 20%;
          }

          @media only screen and (max-width: 600px) {
            width: 20%;
          }

          &__icon {
            font-size: 16px;
          }

          &__label {
            font-size: 16px;
            opacity: 1;
            color: lightgreen;
          }
        }
      }

      &__footer {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        text-align: center;
        margin: 0;
        font-size: 12px;

        @media only screen and (max-width: 900px) {
          display: none;
        }

        @media only screen and (max-height: 600px) {
          display: none;
        }

        &__menu-item {
          font-size: 18px;
          cursor: pointer;
          margin-top: 16px;
          color: #c6c6c6;
        }
      }
    }
  }
}

.sidebar-hide-left {
  left: -100px;
  transition: left 1s ease;
  -moz-transition: left 1s ease;
  -webkit-transition: left 1s ease;
}

.sidebar-slide-right {
  left: 0;
  transition: left 1s ease;
  -moz-transition: left 1s ease;
  -webkit-transition: left 1s ease;
}
