.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}

.vertical-center-100 {
  margin-bottom: auto;
}

.horizontal-center {
  text-align: center;
}

.unclickable {
  cursor: default;
  box-shadow: none;
}
