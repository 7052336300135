@import "./Page.module.scss";

.main-landing-page {
  &__header {
    margin-top: 50px;
    margin-bottom: 50px;

    @media only screen and (max-width: 1400px) {
      margin-bottom: 75px;
    }

    @media only screen and (max-width: 600px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__brand {
    font-size: 48px;
    font-weight: 200;
    letter-spacing: 5px;

    @media only screen and (max-width: 600px) {
      font-size: 36px;
    }

    &__sub-title {
      font-size: 24px;
    }
  }

  &__user-portraits-info {
    justify-content: center;
    font-size: 20px;
    margin-top: 50px;

    @media only screen and (max-width: 600px) {
      font-size: 16px;
      margin-top: 0;
    }
  }

  &__user-portraits {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
  }

  &__portrait {
    text-align: center;
  }
}

.banner::after,
.banner::before {
  content: "";
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 50%;
  transform: skew(15deg) translateX(-50%);
  width: 60%;
  height: 100%;
  background-image: repeating-linear-gradient(60deg, #44ee9f, #33d0ff);
  background-color: $light-gray;
  background-repeat: repeat-y;
  opacity: 0.05;
}

.banner::before {
  transform: skew(-15deg) translateX(-50%);
  overflow: hidden;
}

.banner {
  overflow: hidden;
  min-width: 100%;
}

.landing-page-portrait-column {
  margin-left: 2vh;
  margin-right: 2vh;
  margin-bottom: 20px;
}
